<template>
  <div class="body">
    <vue-particles
      color="#fff"
      :particleOpacity="0.7"
      :particlesNumber="90"
      shapeType="circle"
      :particleSize="4"
      linesColor="#fff"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles>
    <div class="apImgTitle">
      <!--<img src="../../assets/img/app.png" width="200" height="200" alt="" />-->
    </div>
    <div class="apTitle">广西农村户用沼气CCER示范平台</div>
    <div class="logcon">
      <el-form
        :rules="loginRules"
        ref="loginForm"
        :model="loginForm"
        status-icon
        :inline="true"
      >
        <el-form-item prop="username">
          <el-input
            placeholder="请输入用户名"
            prefix-icon="el-icon-user"
            v-model="loginForm.username"
            @keyup.enter.native="handleLogin"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            placeholder="请输入密码"
            prefix-icon="el-icon-lock"
            v-model="loginForm.password"
            type="password"
            show-password
            @keyup.native.enter="handleLogin"
          >
          </el-input>
        </el-form-item>
        <el-button
          type="primary"
          :loading="loginLoading"
          @click.native.prevent="handleLogin"
          plain
          >登录
        </el-button>
      </el-form>
    </div>
  </div>
</template>
<script>
import '@/style/login.scss'
import Cookies from 'js-cookie'

export default {
  data() {
    return {
      loginLoading: false,
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码长度最少为6位', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    /**登录事件**/
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loginLoading = true
          let params = {
            username: this.loginForm.username,
            password: this.loginForm.password
          }
          this.$api.login.login(params).then(res => {
            if (res.code === 200) {
              //token 过期时间
              let serTime = res.timestamp + res.data.expires_in * 1000
              Cookies.set('token', res.data.token) // 放置token到Cookie
              Cookies.set('token_time', serTime) // token过期时间
              Cookies.set('userInfo', res.data) // 用户信息
              Cookies.set('user_orgIds', res.data.orgIds) // 用户机构信息
              sessionStorage.setItem('userId', res.data.userId) // 保存用户到本地会话
              this.loginLoading = false
              // 跳转到首页
              this.$router.push('/')
            } else {
              this.loginLoading = false
              this.$message.error(res.msg)
            }
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss"></style>
